/** @format */

import { cn } from "@/lib/utils";
import * as React from "react";
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  ...props
}, ref) => {
  return <input type={type} className={cn("flex h-10 w-full rounded-md border border-primary-accent--kheradmen bg-white dark:bg-black px-3 py-2 text-sm", "text-black-navigation-bar-foreground--kheradmen dark:text-white-navigation-bar-foreground--kheradmen", "ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium", "placeholder:text-foreground-text--kheradmen", "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-accent--kheradmen focus-visible:ring-offset-2", "disabled:cursor-not-allowed disabled:opacity-50", className)} ref={ref} {...props} />;
});
Input.displayName = "Input";
export { Input };