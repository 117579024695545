/** @format */

"use client";

import React from "react";
import { motion } from "framer-motion";
import { useLocale, useTranslations } from "next-intl";
import { Instagram, Linkedin, Send, Twitter } from "lucide-react";
import FooterLogo from "@/components/shared/footer";
import Link from "@/components/navlinks/NavigationLink";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import NewsLetterForm from "@/components/features/forms/newsletterForm";

// Types
type FooterLinkProps = {
  href: string;
  children: React.ReactNode;
  isExternal?: boolean;
  isEmail?: boolean;
  isPhone?: boolean;
  isAddress?: boolean;
};
type SocialLinkProps = {
  href: string;
  icon: React.ReactElement;
  label: string;
};
type FooterSectionProps = {
  title: string;
  links: Array<Omit<FooterLinkProps, "children"> & {
    id: string;
  }>;
  showNewsletterForm?: boolean;
};

// Animation variants
const fadeInUp = {
  initial: {
    opacity: 0,
    y: 20
  },
  animate: {
    opacity: 1,
    y: 0
  },
  transition: {
    duration: 0.5,
    ease: "easeOut"
  }
};

// Components
const FooterLink: React.FC<FooterLinkProps> = ({
  href,
  children,
  isExternal,
  isEmail,
  isPhone,
  isAddress
}) => {
  const locale = useLocale();
  const isRTL = locale === "fa";
  return <motion.li whileHover={{
    x: isRTL ? -5 : 5
  }} whileTap={{
    scale: 0.95
  }} data-sentry-element="unknown" data-sentry-component="FooterLink" data-sentry-source-file="footer.tsx">
      <Link href={href} target={isExternal ? "_blank" : undefined} rel={isExternal ? "noopener noreferrer" : undefined} className={cn("text-sm transition-colors text-white/70 hover:text-white focus-visible:text-white", isEmail || isPhone ? "ltr" : "", isAddress && "underline decoration-thin")} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
        {children}
      </Link>
    </motion.li>;
};
const SocialLink: React.FC<SocialLinkProps> = ({
  href,
  icon,
  label
}) => <motion.li whileHover={{
  scale: 1.1
}} whileTap={{
  scale: 0.9
}} data-sentry-element="unknown" data-sentry-component="SocialLink" data-sentry-source-file="footer.tsx">
    <Button variant="ghost" size="icon" asChild className="p-2 rounded-full hover:bg-white/10 focus-visible:bg-white/20" data-sentry-element="Button" data-sentry-source-file="footer.tsx">
      <Link href={href} aria-label={label} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
        {React.cloneElement(icon, {
        "aria-hidden": "true",
        className: "h-5 w-5 text-white"
      })}
      </Link>
    </Button>
  </motion.li>;
const FooterSection: React.FC<FooterSectionProps> = React.memo(({
  title,
  links,
  showNewsletterForm
}) => <motion.section className="space-y-4 group" {...fadeInUp}>
      <h2 className="text-sm font-bold tracking-widest text-white uppercase rtl:tracking-tight">
        {title}
      </h2>
      <ul className="space-y-2">
        {links.map(({
      id,
      ...linkProps
    }) => <FooterLink key={id} {...linkProps}>
            {id}
          </FooterLink>)}
      </ul>
      {showNewsletterForm && <NewsLetterForm />}
    </motion.section>);
FooterSection.displayName = "FooterSection";

// Main component
const Footer: React.FC = () => {
  const t = useTranslations("Footer");
  const footerSections = React.useMemo(() => [{
    title: t("links.title"),
    links: [{
      id: t("links.articles"),
      href: "/blog"
    }, {
      id: t("links.robovakil"),
      href: t("links.robovakil"),
      isExternal: true
    }, {
      id: t("links.medguard"),
      href: t("links.medguard"),
      isExternal: true
    }, {
      id: t("links.hami"),
      href: t("links.hami"),
      isExternal: true
    }, {
      id: t("links.carestyle"),
      href: t("links.carestyle"),
      isExternal: true
    }]
  }, {
    title: t("legal.title"),
    links: [{
      id: t("legal.cookie-policy"),
      href: "/cookie-policy"
    }, {
      id: t("legal.privacy-policy"),
      href: "/privacy-policy"
    }, {
      id: t("legal.terms-of-service"),
      href: "/terms-of-service"
    }]
  }, {
    title: t("contact.title"),
    showNewsletterForm: true,
    links: [{
      id: t("contact.email"),
      href: "mailto:hello@kheradmen.com",
      isEmail: true
    }, {
      id: t("contact.phone"),
      href: "tel:+982191031969",
      isPhone: true
    }, {
      id: t("contact.address"),
      href: "#",
      isAddress: true
    }]
  }], [t]);
  const socialLinks = React.useMemo(() => [{
    href: "https://twitter.com/ameshaAI",
    icon: <Twitter />,
    label: t("social.twitter")
  }, {
    href: "https://www.linkedin.com/company/amesha",
    icon: <Linkedin />,
    label: t("social.linkedin")
  }, {
    href: "https://t.me/Kheradmen",
    icon: <Send />,
    label: t("social.telegram")
  }, {
    href: "https://instagram.com/kheradmenn",
    icon: <Instagram />,
    label: t("social.instagram")
  }], [t]);
  return <footer className="text-white bg-gray-900" role="contentinfo" aria-label="Site footer" data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
      <motion.div initial="initial" animate="animate" variants={{
      initial: {
        opacity: 0
      },
      animate: {
        opacity: 1,
        transition: {
          staggerChildren: 0.1
        }
      }
    }} className="container px-4 py-16 mx-auto md:px-8 lg:px-12" data-sentry-element="unknown" data-sentry-source-file="footer.tsx">
        <motion.div className="mb-16" {...fadeInUp} data-sentry-element="unknown" data-sentry-source-file="footer.tsx">
          <FooterLogo data-sentry-element="FooterLogo" data-sentry-source-file="footer.tsx" />
        </motion.div>

        <motion.div className="grid grid-cols-1 gap-12 mb-16 sm:grid-cols-2 lg:grid-cols-3" variants={{
        initial: {
          opacity: 0
        },
        animate: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1
          }
        }
      }} data-sentry-element="unknown" data-sentry-source-file="footer.tsx">
          {footerSections.map((section, index) => <FooterSection key={index} {...section} />)}
        </motion.div>

        <Separator className="mb-8 bg-white opacity-10" data-sentry-element="Separator" data-sentry-source-file="footer.tsx" />

        <motion.div className="flex flex-col items-center justify-between space-y-4 md:flex-row md:space-y-0" {...fadeInUp} data-sentry-element="unknown" data-sentry-source-file="footer.tsx">
          <ul className="flex space-x-4 rtl:space-x-reverse">
            {socialLinks.map((link, index) => <SocialLink key={index} {...link} />)}
          </ul>
          <p className="text-xs text-center md:text-right">
            © {new Date().getFullYear()}{" "}
            <span className="font-medium">KHERADMEN</span>. {t("rights")}
          </p>
        </motion.div>
      </motion.div>
    </footer>;
};
export default React.memo(Footer);