type CountryCode = {
  code: string;
  name: string;
  length: number;
  placeholder?: string;
};

const CountryCodeList: CountryCode[] = [
  { code: "+44", name: "UK", length: 10, placeholder: "7XX XXX XXXX" },
  { code: "+98", name: "IR", length: 10, placeholder: "9XX XXX XXXX" },
];

const defaultCountryCode = CountryCodeList[0];

export type CountryCodeType = (typeof CountryCodeList)[number]["code"];

export { CountryCodeList, defaultCountryCode };
export type { CountryCode };
