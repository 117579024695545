"use client";

import { useCallback, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronUp } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useTranslations } from "next-intl";
const ScrollToTop = () => {
  const [show, setShow] = useState(false);
  const t = useTranslations("Common");
  const handleScroll = useCallback(() => {
    setShow(window.scrollY > 100);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  const scrollToTop = () => window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="ScrollToTop" data-sentry-source-file="scroll-to-top.tsx">
      {show && <motion.div initial={{
      opacity: 0,
      scale: 0.5
    }} animate={{
      opacity: 1,
      scale: 1
    }} exit={{
      opacity: 0,
      scale: 0.5
    }} transition={{
      duration: 0.3
    }} className="fixed z-50 bottom-6 rtl:left-6 ltr:right-6">
          <Button onClick={scrollToTop} variant="default" size="icon" className="transition-all duration-300 rounded-full shadow-lg bg-primary/80 hover:bg-primary dark:bg-secondary/80 dark:hover:bg-secondary text-primary-foreground dark:text-secondary-foreground hover:shadow-xl" aria-label={t("scrollToTop")}>
            <ChevronUp className="w-5 h-5" />
          </Button>
        </motion.div>}
    </AnimatePresence>;
};
export default ScrollToTop;