import { z } from "zod";

export const emailSchema = z
  .string()
  .email("invalid_email")
  .min(5, "email_too_short")
  .max(254, "email_too_long")
  .refine((email) => {
    const [localPart, domain] = email.split("@");
    return localPart.length <= 64 && domain.length <= 255;
  }, "email_parts_too_long")
  .refine((email) => {
    const tld = email.split(".").pop() || "";
    return tld.length >= 2 && tld.length <= 63;
  }, "invalid_tld_length");
