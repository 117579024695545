/** @format */

"use client";

import React, { FormEvent, useState, useEffect } from "react";
import { useTranslations } from "next-intl";
import { ValidationSpecificErrors } from "@/types/validationErrors";
import { Button } from "@/components/ui/button";
import { CheckCircleIcon, Loader2 } from "lucide-react";
import { Field } from "@/components/features/fields/Field";
import { z } from "zod";
import { emailSchema } from "@/schema/email";
import { useToast } from "@/components/ui/use-toast";
import { useSekkei } from "@/context/SekkeiProvider";
const formSchema = z.object({
  email: emailSchema
});
const NewsLetterForm: React.FC = () => {
  const t = useTranslations("Footer");
  const {
    toast
  } = useToast();
  const {
    subscribe
  } = useSekkei();
  const {
    execute,
    data,
    isLoading,
    error
  } = subscribe();
  const [mailError, setMailError] = useState<ValidationSpecificErrors<"email">[]>();
  useEffect(() => {
    if (data?.status) {
      toast({
        title: t("newsletter.subscribed"),
        variant: "success"
      });
    }
  }, [data, t, toast]);
  useEffect(() => {
    if (error) {
      toast({
        title: t("newsletter.invalidEmail"),
        variant: "destructive"
      });
    }
  }, [error, t, toast]);
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const validatedFields = formSchema.safeParse(Object.fromEntries(formData));
    if (!validatedFields.success) {
      setMailError(validatedFields.error.flatten().fieldErrors.email as ValidationSpecificErrors<"email">[]);
      return;
    }
    const {
      email
    } = validatedFields.data;
    await execute({
      email,
      type: "newsletter"
    });
  };
  return <div className="pt-8" data-sentry-component="NewsLetterForm" data-sentry-source-file="newsletterForm.tsx">
      <h4 className="mb-2 text-lg font-semibold text-white">
        {t("newsletter.title")}
      </h4>
      <form onSubmit={onSubmit} className="flex items-center w-full">
        <div className="relative flex-grow">
          <Field<"email"> disabled={isLoading || !!data?.status} id="email" type="email" placeholder={t("newsletter.emailPlaceholder")} required className="w-full h-10 px-4 transition-all duration-300 border-none rounded-md bg-white/10 focus:ring-2 focus:ring-primary" errors={mailError} icon={<Button type="submit" disabled={isLoading || !!data?.status} variant="ghost" size="icon" className="absolute transition-all duration-300 -translate-y-1/2 right-1 top-1/2 hover:bg-transparent">
                {isLoading ? <Loader2 className="w-4 h-4 animate-spin text-primary" /> : <svg className="w-4 h-4 text-gray-400 transition-colors duration-300 hover:text-primary" width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 9L0 17.6603L0 0.339746L15 9Z" fill="currentColor" />
                  </svg>}
              </Button>} data-sentry-element="Field" data-sentry-source-file="newsletterForm.tsx" />
        </div>
      </form>
      {data?.status && <p className="flex items-center gap-2 mt-2 text-sm text-green-600 animate-fadeIn">
          <CheckCircleIcon className="w-4 h-4" /> {t("newsletter.subscribed")}
        </p>}
    </div>;
};
export default NewsLetterForm;