/** @format */

"use client";

import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Skeleton } from "@/components/ui/skeleton";
import { CookieKeys, ThemeValue } from "@/cookies/types";
import { useCookie } from "@/hooks/use-cookie";
import { useMounted } from "@/hooks/use-mounted";
import { Moon, Sun } from "lucide-react";
import { useTranslations } from "next-intl";
import { useTheme } from "next-themes";
import React from "react";
import { motion } from "framer-motion";
import { useApp } from "@/context/AppProvider";
const themeOptions: {
  theme: ThemeValue;
  icon: React.ReactElement;
  labelId: "light" | "dark";
}[] = [{
  theme: "light",
  icon: <Sun />,
  labelId: "light"
}, {
  theme: "dark",
  icon: <Moon />,
  labelId: "dark"
}];
const ModeToggle: React.FC = () => {
  const t = useTranslations("ModeToggle");
  const {
    isDarkModePereferred
  } = useApp();
  const {
    setTheme,
    theme
  } = useTheme();
  const [cookie, setCookie] = useCookie(CookieKeys.Theme, isDarkModePereferred ? "dark" : "light");
  const isMounted = useMounted();
  React.useEffect(() => {
    if (cookie && cookie !== theme) {
      setTheme(cookie);
    }
  }, [cookie, setTheme, theme]);
  const handleToggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    setCookie(newTheme, {
      path: "/"
    });
  };
  if (!isMounted) {
    return <Skeleton className="h-9 w-9" aria-label={t("themeToggleLoading")} />;
  }
  const currentThemeOption = themeOptions.find(option => option.theme === theme) || themeOptions[0];
  return <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="ModeToggle" data-sentry-source-file="ModeToggle.tsx">
      <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="ModeToggle.tsx">
        <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="ModeToggle.tsx">
          <motion.div whileTap={{
          scale: 0.95
        }} data-sentry-element="unknown" data-sentry-source-file="ModeToggle.tsx">
            <Button variant="ghost" size="icon" onClick={handleToggleTheme} className="flex items-center justify-center" aria-label={t("toggleTheme")} data-sentry-element="Button" data-sentry-source-file="ModeToggle.tsx">
              {React.cloneElement(currentThemeOption.icon, {
              className: "h-5 w-5 text-foreground transition-colors",
              "aria-hidden": "true"
            })}
            </Button>
          </motion.div>
        </TooltipTrigger>
        <TooltipContent side="bottom" align="center" data-sentry-element="TooltipContent" data-sentry-source-file="ModeToggle.tsx">
          <p className="text-sm">
            {t("switchTo", {
            theme: t(theme === "light" ? "dark" : "light")
          })}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>;
};
export default ModeToggle;