/** @format */

"use client";

import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Locale } from "@/i18n-config";
import { motion } from "framer-motion";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { useApp } from "../../context/AppProvider";
const LocaleToggle = () => {
  const t = useTranslations("LocaleToggle");
  const {
    allowedLocales,
    switchLocaleTo,
    locale,
    localePending
  } = useApp();
  const targetLocale: Locale = locale === "fa" ? "en" : "fa";
  if (allowedLocales.length == 1 || !allowedLocales.includes(targetLocale)) {
    return null;
  }
  return <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="LocaleToggle" data-sentry-source-file="LocaleToggle.tsx">
      <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="LocaleToggle.tsx">
        <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="LocaleToggle.tsx">
          <motion.div whileTap={{
          scale: 0.95
        }} data-sentry-element="unknown" data-sentry-source-file="LocaleToggle.tsx">
            <Button variant="ghost" size="icon" className={cn("flex items-center justify-center text-sm font-semibold", localePending && "opacity-50")} asChild data-sentry-element="Button" data-sentry-source-file="LocaleToggle.tsx">
              <Link href="#" onClick={e => {
              e.preventDefault();
              switchLocaleTo(targetLocale);
            }} hrefLang={targetLocale} lang={targetLocale} data-sentry-element="Link" data-sentry-source-file="LocaleToggle.tsx">
                {targetLocale.toUpperCase()}
              </Link>
            </Button>
          </motion.div>
        </TooltipTrigger>
        <TooltipContent side="bottom" align="center" data-sentry-element="TooltipContent" data-sentry-source-file="LocaleToggle.tsx">
          <p className="text-sm">
            {t("switchTo", {
            locale: targetLocale === "en" ? "English" : "Persian"
          })}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>;
};
export default LocaleToggle;