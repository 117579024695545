"use client";

import { type Pathnames } from "@/i18n-config";
import { cn } from "@/lib/utils";
import { Link, type LinkProps } from "@/navigation";
import { useSelectedLayoutSegment } from "next/navigation";
import { type ComponentProps, ForwardedRef, forwardRef, JSX } from "react";
type NavigationLinkProps<T extends keyof Pathnames> = Omit<LinkProps, "href"> & Omit<ComponentProps<"a">, keyof LinkProps> & {
  href: T;
};
function NavigationLinkComponent<T extends keyof Pathnames>({
  href,
  className,
  ...rest
}: NavigationLinkProps<T>, ref: ForwardedRef<HTMLAnchorElement>) {
  const selectedLayoutSegment = useSelectedLayoutSegment();
  const pathname = selectedLayoutSegment ? `/${selectedLayoutSegment}` : "/";
  const isActive = pathname === href;
  return <Link ref={ref} aria-current={isActive ? "page" : undefined} className={cn(" transition-colors tracking-wider rtl:tracking-normal", isActive ? "text-white/80 hover:text-white font-semibold" : "text-white/70 hover:text-white/90 font-medium", className)} href={href as LinkProps["href"]} {...rest} data-sentry-element="Link" data-sentry-component="NavigationLinkComponent" data-sentry-source-file="NavigationLink.tsx" />;
}
NavigationLinkComponent.displayName = "NavigationLink";
const NavigationLink = forwardRef(NavigationLinkComponent) as <T extends keyof Pathnames>(props: NavigationLinkProps<T> & {
  ref?: ForwardedRef<HTMLAnchorElement>;
}) => JSX.Element;
export default NavigationLink;