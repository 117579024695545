/** @format */

"use client";

import LocaleToggle from "@/components/shared/LocaleToggle";
import ModeToggle from "@/components/shared/ModeToggle";
import { KheradmenTextLogo } from "@/components/icons/logo";
import NavigationLink from "@/components/navlinks/NavigationLink";
import { Pages } from "@/i18n-config";
import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { Menu, X } from "lucide-react";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";
import { usePathname, useRouter } from "@/navigation";
import { FC, HTMLAttributes, MouseEvent, useCallback, useEffect, useState } from "react";
interface NavigationItem {
  href: string;
  label: Pages;
  page?: string;
}
type Page = string | undefined;
const getHref = (pathname: string, page: Page, href: string): string => {
  const basePage: string = page || "/";
  if (href.startsWith("#")) {
    if (pathname === basePage) {
      return href;
    }
    return basePage;
  }
  if (href === basePage) {
    return href;
  }
  if (pathname === basePage) {
    return basePage;
  }
  if (pathname.startsWith(basePage)) {
    return href;
  }
  return `${basePage.replace(/\/$/, "")}/${href.replace(/^\//, "")}`;
};
interface NavigationBarProps extends HTMLAttributes<HTMLDivElement> {
  NavigationItems: NavigationItem[];
}
const NavigationBar: FC<NavigationBarProps> = ({
  NavigationItems,
  className
}) => {
  const locale = useLocale();
  const t = useTranslations("Navigation");
  const pathname = usePathname();
  const router = useRouter();
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 10);
    window.addEventListener("scroll", handleScroll, {
      passive: true
    });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const toggleMenu = () => setIsMenuOpen(prev => !prev);
  const NavigationLinks: FC<{
    Mobile?: boolean;
  }> = ({
    Mobile
  }) => {
    const handleClick = useCallback((e: MouseEvent<HTMLAnchorElement>, href: string, page: Page) => {
      e.preventDefault();
      const isHashUrl = href.startsWith("#");
      const targetPage = page || "/";
      const isCurrentPage = pathname === targetPage;
      if (isHashUrl) {
        if (!isCurrentPage) {
          router.push(targetPage);
          setTimeout(() => {
            const target = document.querySelector(href);
            target?.scrollIntoView({
              behavior: "smooth"
            });
          }, 400);
        } else {
          const target = document.querySelector(href);
          target?.scrollIntoView({
            behavior: "smooth"
          });
        }
      } else {
        router.push(getHref(pathname, page, href));
      }
    }, []);
    return <ul className={cn("flex", Mobile ? "flex-col space-y-4" : "space-x-6 rtl:space-x-reverse")} data-sentry-component="NavigationLinks" data-sentry-source-file="NavigationBar.tsx">
        {NavigationItems.map(({
        href,
        label,
        page
      }, index) => <li key={href} className="relative" onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
            <NavigationLink href={getHref(pathname, page, href)} className={cn("text-sm transition-colors duration-200 font-bold py-2 hover:text-foreground-text--kheradmen", pathname.endsWith(href) ? "text-foreground-text--kheradmen/90" : "text-foreground-text--kheradmen/70 ", Mobile && "text-lg")} onClick={(e: MouseEvent<HTMLAnchorElement>) => handleClick(e, href, page)}>
              {t(label)}
            </NavigationLink>
            <motion.div className="absolute bottom-0 left-0 h-0.5 bg-foreground-text--kheradmen" initial={{
          width: "0%"
        }} animate={{
          width: hoveredIndex === index || pathname.endsWith(href) && hoveredIndex === null ? "100%" : "0%"
        }} transition={{
          duration: 0.3
        }} />
          </li>)}
      </ul>;
  };
  return <header className={cn("fixed top-0 left-0 right-0 z-50 transition-all duration-300 py-4 ", scrolled || isMenuOpen ? "backdrop-blur-xl shadow-sm navigation-bar" : "bg-transparent", !scrolled && !isMenuOpen && "pt-4 lg:pt-12", className)} data-sentry-component="NavigationBar" data-sentry-source-file="NavigationBar.tsx">
      <div className="container flex items-center justify-between px-4 md:px-8">
        <div className="flex items-center space-x-8 rtl:space-x-reverse">
          <Link href={`/${locale}`} className="flex-shrink-0" aria-label="Home" data-sentry-element="Link" data-sentry-source-file="NavigationBar.tsx">
            <KheradmenTextLogo className="w-auto h-4 transition-colors duration-200 text-foreground-text--kheradmen/90" aria-hidden="true" data-sentry-element="KheradmenTextLogo" data-sentry-source-file="NavigationBar.tsx" />
          </Link>
          <nav className="hidden md:flex" aria-label="Main navigation">
            <NavigationLinks data-sentry-element="NavigationLinks" data-sentry-source-file="NavigationBar.tsx" />
          </nav>
        </div>

        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <LocaleToggle data-sentry-element="LocaleToggle" data-sentry-source-file="NavigationBar.tsx" />
          <ModeToggle data-sentry-element="ModeToggle" data-sentry-source-file="NavigationBar.tsx" />
          <button className="md:hidden" onClick={toggleMenu} aria-label="Toggle menu">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="NavigationBar.tsx">
        {isMenuOpen && <motion.div initial={{
        opacity: 0,
        y: -20
      }} animate={{
        opacity: 1,
        y: 0
      }} exit={{
        opacity: 0,
        y: -20
      }} transition={{
        duration: 0.3
      }} className="absolute left-0 right-0 p-4 shadow-md md:hidden bg-navigation-background/70 backdrop-blur-xl top-full">
            <NavigationLinks Mobile />
          </motion.div>}
      </AnimatePresence>
    </header>;
};
NavigationBar.displayName = "Navigation";
export default NavigationBar;