/** @format */

"use client";

import React, { useId, useState, useEffect } from "react";
import { useLocale, useTranslations } from "next-intl";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { CountryCodeList, CountryCodeType, defaultCountryCode } from "@/lib/phone";
import { cn } from "@/lib/utils";
import { ValidationSpecificErrors } from "@/types/validationErrors";
type CustomInputTypes = "phone_number_input";
type FieldProps<T extends string> = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> & {
  id: T;
  placeholder: string;
  errors?: ValidationSpecificErrors<T>[];
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"] | CustomInputTypes;
  label?: string;
  icon?: React.ReactNode;
};
const Field = <T extends string,>({
  id,
  type = "text",
  placeholder,
  errors,
  label,
  value: externalValue,
  onChange: externalOnChange,
  icon,
  ...restProps
}: FieldProps<T>) => {
  const translate = useTranslations("Errors");
  const locale = useLocale();
  const fieldId = useId();
  const isRTL = locale !== "en";
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedCountryCode, setSelectedCountryCode] = useState<CountryCodeType>(defaultCountryCode.code);
  useEffect(() => {
    if (externalValue !== undefined) {
      setInputValue(String(externalValue));
    }
  }, [externalValue]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    externalOnChange?.(event);
  };
  const getTextDirection = (value: string) => {
    const hasNonLatinChar = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(value);
    return value.length > 0 ? hasNonLatinChar ? "rtl" : "ltr" : locale === "en" ? "ltr" : "rtl";
  };
  const isPhoneInput = type === "phone_number_input";
  const composedValue = isPhoneInput ? `${selectedCountryCode}${inputValue}` : inputValue;
  const currentTextDirection = getTextDirection(inputValue);
  return <div className={cn("space-y-2", errors && "space-y-2")} data-sentry-component="Field" data-sentry-source-file="Field.tsx">
      <input style={{
      display: "none"
    }} name={id} onChange={() => {}} value={composedValue} />
      {label && <Label htmlFor={fieldId}>{label}</Label>}
      <div className={cn("flex flex-col sm:flex-row gap-2", isRTL ? "sm:flex-row-reverse" : "sm:flex-row", isPhoneInput && "sm:space-x-2 sm:space-x-reverse")}>
        {isPhoneInput && <Select value={selectedCountryCode} onValueChange={setSelectedCountryCode} disabled={restProps.disabled}>
            <SelectTrigger className="w-full sm:w-auto">
              <SelectValue placeholder={defaultCountryCode.code} />
            </SelectTrigger>
            <SelectContent>
              {CountryCodeList.map(countryCode => <SelectItem key={countryCode.code} value={countryCode.code}>
                  {countryCode.code} {countryCode.name}
                </SelectItem>)}
            </SelectContent>
          </Select>}
        <div className={cn("relative flex-grow")}>
          <Input id={fieldId} placeholder={placeholder} type={isPhoneInput ? "tel" : type} dir={currentTextDirection} value={inputValue} className={cn(icon && (currentTextDirection === "rtl" ? "pl-10" : "pr-10"), !icon && "px-3")} onChange={handleInputChange} maxLength={isPhoneInput ? CountryCodeList.find(code => code.code === selectedCountryCode)?.length : undefined} {...restProps} data-sentry-element="Input" data-sentry-source-file="Field.tsx" />
          {icon && <div className={cn("absolute inset-y-0 flex items-center", currentTextDirection === "rtl" ? "left-0 pl-3 rotate-180" : "right-0 pr-3")}>
              {icon}
            </div>}
        </div>
      </div>
      {errors && errors.map((error, index) => <p className="text-sm text-destructive" key={index}>
            {/* @ts-expect-error @typescript-eslint/no-explicit-any */}
            {translate(error)}
          </p>)}
    </div>;
};
export { Field };